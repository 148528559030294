/* -Text variations
-----------------------------------------------------------------------------*/
@mixin large-content-text {
  @include media-min($breakpoint-type-small) {
    font-size: rem(18px);
  }
  @include media-min($breakpoint-type-medium) {
    font-size: rem(22px);
  }
}

/* Larger and smaller text */
//important needed to override inline styles from cision
%lead-text {
  margin-bottom: em($base-text-spacing, $lead-text-size);
  font-size: rem($lead-text-size) !important;
  font-weight: $font-weight-light;
  font-family: $font-stack-alt !important;

  @include media-max($breakpoint-type-medium) {
    font-size: rem($base-font-size + 8px) !important;
  }

  @include media-max($breakpoint-type-small) {
    font-size: rem($base-font-size + 4px) !important;
  }
}
@mixin lead-text-reset {
  margin-bottom: 0;
  font-size: 1em;
  font-weight: normal;
}

%sub-text {
  margin-bottom: em($base-text-spacing, $sub-text-size);
  font-size: rem($sub-text-size);
}

%quote-text {
  margin-bottom: em($base-text-spacing, $quote-text-size);
  line-height: 1.3;
  font-size: rem($quote-text-size);
  font-style: italic;
  font-family: $font-stack-alt !important;
  font-weight: $font-weight-medium !important;

  @include media-max($breakpoint-type-small) {
    font-size: rem($base-font-size + 4px);
  }
  @include media-between($breakpoint-type-small, $breakpoint-type-medium) {
    font-size: rem(24px);
  }
}

.lead-text {
  @extend %lead-text;
}
.sub-text {
  @extend %sub-text;
}
.quote-text {
  @extend %quote-text;
}

.lead-text,
.sub-text,
.intro-text {
  p,
  ol,
  ul {
    margin-bottom: inherit;
  }
}

// Inline text alongside things like icons
.text {
  vertical-align: middle;

  // Restore default alignment if it appears on its own
  &:only-child {
    vertical-align: baseline;
  }
}
