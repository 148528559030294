/* -Key persons header with key person block
---------------------------------------------------------------------------- */
.key-person-wrapper {
  display: flex;
  text-align: center;
  width: auto;
}

.key-persons-header {
  padding: 30px;
  text-align: center;
  border-top: 1px solid #292929;
  background-color: $color-header-background;

  @include media-max(rem(600px)) {
    padding: 10px;
  }
  @include media-max(rem(400px)) {
    padding: 5px;
  }
}
.key-persons-header-items {
  @include grid-wrap(60px, 30px);
  @include grid-pull-bottom(30px, 15px);
}

.key-persons-header-item {
  @include grid-item(1, 60px, 30px);
  @include grid-margin-bottom(30px, 15px);

  opacity: 0.5;
  color: #fff;

  &:hover,
  &:focus {
    opacity: 1;
  }
  .key-person {
    &:hover,
    &:focus {
      transition: none;
      transform: none;
    }
  }
}

.key-persons-header-item--active {
  opacity: 1;
}

.key-persons-header--cols-2 {
  .key-persons-header-item {
    @include grid-item(1/2, 60px, 30px);
  }
}

.key-persons-header--cols-3 {
  .key-persons-header-item {
    @include grid-item(1/3, 60px, 30px);

    @include media-max(rem(500px)) {
      @include grid-item(1 / 2, 60px, 30px);
      .key-person {
        img {
          width: 40px;
          height: 40px;
        }
        font-size: rem(14px);
      }
    }

    @include media-max(rem(400px)) {
      @include grid-item(1 / 2, 60px, 30px);
      .key-person {
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}

.key-persons-header--cols-4 {
  .key-persons-header-item {
    @include grid-item(1/4, 60px, 30px);

    @include media-max(rem(600px)) {
      @include grid-item(1 / 2, 60px, 30px);

      .key-person {
        img {
          width: 40px;
          height: 40px;
        }
        font-size: rem(14px);
      }
    }

    @include media-max(rem(400px)) {
      @include grid-item(1 / 2, 60px, 30px);
      .key-person {
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
