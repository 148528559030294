.highlighted-news-container {
  @include section-margin;

  display: flex;
  align-items: flex-start;
}

.highlighted-news-content {
  width: 40%;
}

.highlighted-news-image-wrapper {
  width: 60%;
  margin-right: 60px;
  display: flex;
  justify-content: center;
}

.highlighted-news-image,
.highlighted-news-item {
  flex-shrink: 1;
  flex-grow: 0;
  border-radius: 10px;
  color: #fff;

  img {
    border-radius: 10px;
    box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.1);
  }
  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

.highlighted-news-image {
  position: relative;
  // Ensure it occupies space while lazy loading
  min-width: 80%;
  min-height: 8em;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    background: linear-gradient(
      -180deg,
      rgba(0, 0, 0, 0) 43%,
      rgba(0, 0, 0, 0.7) 100%
    );
  }
}
.highlighted-news-link {
  position: absolute;
  right: 5%;
  bottom: 5%;
  font-size: rem(18px);
  font-weight: bold;
  font-family: $font-stack-alt;
  text-transform: uppercase;
  color: #fff;
  z-index: 1;

  .icon--arrow {
    margin-left: 15px;
    vertical-align: baseline;
  }
}
.highlighted-news-image-content {
  position: absolute;
  left: 5%;
  bottom: 5%;
  max-width: 50%;
  color: #fff;
  z-index: 1;

  h3 {
    margin-bottom: 0;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  }
}

.highlighted-news-item {
  position: relative;
  min-height: 380px;
  width: 60%;
  margin-right: 60px;
  background-image: linear-gradient(180deg, #db001b 0%, $color-red 100%);
}

.highlighted-news-link {
  position: absolute;
  right: 5%;
  bottom: 5%;
  font-size: rem(18px);
  font-weight: bold;
  font-family: $font-stack-alt;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

  .icon--arrow {
    margin-left: 15px;
  }
}
.highlighted-news-item-content {
  position: absolute;
  left: 5%;
  bottom: 5%;
  max-width: 50%;
  color: #fff;

  h3 {
    margin-bottom: 0;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  }
}

.highlighted-news-article {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 10px 0;
  border-top: 1px solid $color-beige;

  h5 {
    margin-bottom: 0;
  }

  .publish-date {
    margin-right: 15px;
    flex-shrink: 0;
    margin-bottom: 0;
    color: #292929;
  }

  &:last-child {
    border-bottom: 1px solid $color-beige;
  }
}

@include media-max(rem(960px)) {
  .highlighted-news-container {
    flex-direction: column;

    h5 {
      font-size: rem(20px);
    }
  }

  .highlighted-news-image-wrapper {
    width: 100%;
    margin-bottom: 15px;
    margin-right: 0;
    display: flex;
    justify-content: flex-start;
  }
  .highlighted-news-image {
    max-width: 100%;
  }
  .highlighted-news-item {
    width: 100%;
    max-width: none;
    margin-bottom: 15px;
    margin-right: 0;
  }

  .highlighted-news-content {
    width: 100%;
  }
}

@include media-max(rem(500px)) {
  .highlighted-news-link {
    font-size: rem(14px);
    .icon--arrow {
      margin-left: 3px;
    }
  }
  .highlighted-news-image-content,
  .highlighted-news-item-content {
    h3 {
      font-size: rem(18px);
    }
  }
}

@include media-min(rem(1050px)) {
  .highlighted-news-item {
    margin-right: 100px;
  }
  .highlighted-news-image-wrapper {
    margin-right: 100px;
  }
  .highlighted-news-image {
    min-width: 100%;
    min-height: 15em;
  }
}
