.link-blocks {
  @include section-margin;
  @include grid-wrap(40px, 40px);

  @include media-min(rem(1200px)) {
    @include grid-wrap(80px, 40px);
  }

  display: flex;
  flex-wrap: wrap;
}

.link-block {
  @include grid-item(1, 40px, 40px);
  @include grid-margin-bottom(40px, 40px);
  position: relative;
  display: flex;
  justify-content: center;

  @include media-min(rem(1200px)) {
    @include grid-item(1, 80px, 40px);
    @include grid-margin-bottom(80px, 40px);
    display: flex;
  }

  @include media-min($breakpoint-grid-tight) {
    @include grid-item-columns(1/2);
  }

  @include media-min(rem(1000px)) {
    @include grid-item-columns(1/3);
  }
  .overlay-link {
    @include grid-gutter-rule(left);
  }
}

.link-block-inner {
  position: relative;
  box-shadow: 0px 5px 7px 0px rgba(193, 172, 147, 0.4);
  background-color: #fff;
  width: 100%;
  max-width: 400px;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  transition: transform 0.15s ease, box-shadow 0.15s ease;

  .overlay-link:hover ~ &,
  .overlay-link:focus ~ & {
    text-decoration: underline;
    transform: scale(1.02);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  }
}

.link-block-content {
  padding: 30px;
  border-radius: 0px 0px 10px 10px;
}

.link-block-content-image {
  border-bottom: 1px solid #f1ece6;
  height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.link-block-image {
  height: 70px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.link-block-title {
  margin-bottom: 10px;
  font-size: rem(32px);
  font-family: $font-stack-alt;
  font-weight: $font-weight-medium;
  font-style: normal;
}

.link-block-url {
  span {
    color: inherit;
    font-size: rem(18px);
    text-transform: uppercase;
    font-family: $font-stack-alt;
    font-weight: $font-weight-bold;
  }

  .icon--arrow {
    margin-left: 10px;
  }
}
