/* -Blurb component - block of image, heading, short text and a link
-----------------------------------------------------------------------------*/
.blurb {
  max-width: 400px;
  text-align: left;
}

.blurb--svg {
  .blurb-inner {
    display: flex;
  }
  .blurb-image {
    flex: 1 1 15%;
    margin-bottom: 15px;
  }
  .blurb-content {
    flex: 1 1 85%;
  }
}

.blurb--img {
  .blurb-image {
    margin-bottom: 10px;
  }
}

.blurb-content {
  font-size: rem(16px);
  margin-left: 10px;

  @include media-max(rem(700px)) {
    font-size: rem(14px);
  }
  p {
    margin: 0;
  }
  .link-block {
    font-weight: $font-weight-medium;
  }
}

.blurb-title {
  margin-bottom: 5px;
  font-size: rem(20px);
}

.blurb-text {
  margin-bottom: 5px;
}

/*--------------- List of blurbs (blurbs block, news...) ---------------*/
.blurb-list-wrap {
  @include section-margin;

  text-align: center;
}
.blurb-list-wrap--cols-3 {
  @include wrap-width;
}
.blurb-list-wrap--cols-4 {
  @include wrap-width-wide;
}

.blurb-list {
  @include grid-wrap;

  margin-bottom: -50px;

  .blurb {
    @include grid-item(1);

    margin-bottom: 50px;
  }
}

@include media-min(rem(650px)) {
  .blurb-list--cols-2,
  .blurb-list--cols-3,
  .blurb-list--cols-4 {
    text-align: left;

    .blurb {
      @include grid-item(1 / 2);
    }
  }

  .blurb-list-wrap--cols-4 {
    @include wrap-width-thin;
  }

  .blurb-list--count-4 {
    .blurb {
      @include grid-item(1 / 2);
    }
    text-align: center;
  }

  .blurb--svg {
    .blurb-inner {
      display: inline-block;
    }

    .blurb-content {
      margin-left: 0;
    }
  }
}

@include media-min(rem(1000px)) {
  .blurb-list--cols-3 .blurb,
  .blurb-list--cols-4 .blurb {
    @include grid-item(1 / 3);
  }

  .blurb-list-wrap--cols-4 {
    @include wrap-width-wide;
  }

  .blurb-list--count-4 .blurb {
    @include grid-item(1 / 4);
  }
}
