/* -Key persons block with key person block
---------------------------------------------------------------------------- */

// Key person
.key-person {
  font-family: $font-stack-alt-second;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  transition: transform 0.15s ease;

  &:hover,
  &:focus {
    text-decoration: underline;
    transform: scale(1.1);
  }
  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
}

/*---------------------------------------------------------------------------- */

// Key persons list
.key-persons-block {
  @include section-margin;

  text-align: center;
}

.key-persons-block-text {
  @include large-content-text;
}

.key-persons-block-items {
  @include grid-wrap(60px, 30px);
  @include grid-pull-bottom(60px, 30px);
}

.key-persons-block-item {
  @include grid-item(1, 60px, 30px);
  @include grid-margin-bottom(60px, 30px);
}

.key-persons-block--cols-2 {
  .key-persons-block-item {
    @include grid-item(1/2, 60px, 30px);
  }
}

.key-persons-block--cols-3 {
  .key-persons-block-item {
    @include grid-item(1/3, 60px, 30px);

    @include media-max(rem(400px)) {
      @include grid-item(1 / 2, 60px, 30px);
    }
  }
}

.key-persons-block--cols-4 {
  .key-persons-block-item {
    @include grid-item(1/4, 60px, 30px);

    @include media-max(rem(600px)) {
      @include grid-item(1 / 2, 60px, 30px);
    }
  }
}
