/* - News article full
---------------------------------------------------------------------------- */
$news-article-content-spacing: 45px;

.news-article-page-container {
  display: flex;
  padding: 40px 20px;

  .time,
  .date {
    font-size: rem(18px);
    font-family: $font-stack-main;
  }
  .time {
    padding-right: 20px;
    border-right: 1px solid;
  }
  .date {
    padding: 0 20px;
  }

  .news-article-image {
    max-width: 750px;
    text-align: center;
    background-color: $color-beige;
  }
  .news-article-intro {
    margin-top: 25px;
    margin-bottom: $news-article-content-spacing;

    * {
      @extend %lead-text;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  // News article body
  .news-article-body {
    margin-bottom: $news-article-content-spacing !important;
    font-family: $font-stack-main;

    // Using * to get all child elements
    // Using important to overide inline-styles from Cision
    * {
      @include large-content-text;

      &:last-child {
        margin-bottom: 0;
      }
    }

    h2,
    strong {
      margin-top: $news-article-content-spacing !important;

      // Using * to get all child elements
      // Using important to overide inline-styles from Cision
      * {
        margin-bottom: 5px !important;
        line-height: 1.25 !important;
        font-size: $h2 !important;
        font-weight: $font-weight-medium !important;
        font-family: $font-stack-alt !important;
        color: inherit !important;

        @include media-max($breakpoint-type-medium) {
          font-size: rem($h2 - 4px) !important;
        }

        @include media-max($breakpoint-type-small) {
          font-size: rem($h2 - 8px) !important;
        }
      }
    }

    // Not displaying images(from Cision) in body text of news article
    img {
      display: none !important;
    }
  }

  // Quote
  .news-article-quote {
    display: flex;
    margin-bottom: $news-article-content-spacing;

    .icon--quote-sign {
      flex-shrink: 0;
      margin-right: 30px;
    }

    @include media-between(rem(450px), rem(700px)) {
      .icon--quote-sign {
        margin-right: 20px;
        width: 50px;
        height: 50px;
      }
    }

    @include media-max(rem(450px)) {
      .icon--quote-sign {
        margin-right: 10px;
        width: 30px;
        height: 30px;
      }
    }
  }
  .news-article-quote-text {
    @extend %quote-text;

    margin-bottom: 10px;
  }
  .news-article-quote-author {
    margin-left: 30px;
    margin-bottom: 0;
    font-size: rem(22px);

    .small-hr {
      position: relative;

      &::before {
        content: '';
        top: 50%;
        left: -30px;
        position: absolute;
        width: 20px;
        height: 2px;
        background-color: $color-gray-dark;

        @include media-max($breakpoint-type-small) {
          width: 12px;
        }
      }
    }

    @include media-max($breakpoint-type-small) {
      font-size: rem(16px);
    }
  }
}

// Contact info
hr {
  color: #cecece;
}
.news-article-contact-info {
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 30px;
  background-color: $color-light-beige;
  box-shadow: 0px 5px 7px 0px rgba(193, 172, 147, 0.4);
  p {
    margin-bottom: 0;
    font-size: rem(18px) !important;

    @include media-max($breakpoint-type-small) {
      font-size: rem(14px) !important;
    }
  }
}

// Tags
.news-article-tags {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;

  .news-article-tag {
    color: $color-red;
    &:not(:last-child) {
      padding-right: 10px;
    }
    &:not(:first-child) {
      padding-left: 10px;
      border-left: 1px solid $color-gray-dark;

      @include media-max(rem(600px)) {
        border-left: none;
        padding-left: 0;
        padding-right: 15px;
      }
    }
  }
}

// Footer with media
.news-article-media-and-share {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  width: 100%;

  @include media-max(rem(1000px)) {
    flex-direction: column;
  }
}
.social-share-icons {
  width: 40%;
  display: flex;
  flex-direction: column;
}
.share-icons {
  margin-top: 20px;
  .icon {
    height: 30px;
    width: 30px;
  }
  a {
    &:not(:last-child) {
      margin-right: 20px;
    }
    &:hover {
      opacity: 0.7;
    }
  }
}
.media-items {
  width: 60%;
  padding-right: 60px;

  @include media-max(rem(1000px)) {
    margin-bottom: 20px;
    padding-right: 0;
    width: 100%;
  }
}
.icon--linkedin {
  color: #0077b5;
}

.icon--facebook {
  color: #3c5a99;
}

.icon--twitter {
  border-radius: 3px;
  background-color: #1da1f2;
  color: white;
}
.icon--mail {
  color: #4a90e2;
}

.news-article-media-thumbs {
  @include grid-wrap(20px, 10px);

  max-width: 260px;
}

.media-item-thumb-item {
  @include grid-item(1/4, 20px, 10px);
  @include grid-margin-bottom(20px, 10px);

  position: relative;

  img {
    width: 45px;
    height: 45px;
  }
  .overlay-link {
    @include grid-gutter-rule(left, 20px, 10px);
  }

  &:hover,
  &:focus {
    opacity: 0.7;
  }
}

// Columns layout for images
.news-article-page-container {
  $col-grid-gutter: 50px;
  $col-grid-gutter-tight: $col-grid-gutter / 2;

  .news-article-images-block--cols-2 {
    column-gap: $col-grid-gutter;

    @include when-grid-tight {
      column-gap: $col-grid-gutter-tight;
    }
  }
  .news-article-images-block--cols-2 {
    column-count: 2;
  }

  .news-article-images-block--cols-2 {
    @include grid-pull-bottom($col-grid-gutter, $col-grid-gutter-tight);

    padding-bottom: $text-spacing;

    .news-article-images-block-item-image {
      // Margin breaks the columns in Chrome
      @include grid-padding-bottom($col-grid-gutter, $col-grid-gutter-tight);

      // Create a new block formatting context to prevent potential spilling
      // from one column to another
      overflow: hidden;
    }
  }
}

// Media queries when sidebar is added

@include media-max(rem(850px)) {
  .news-article-page-container {
    flex-direction: column;

    .newsroom-sidebar-small {
      padding: 0 34px !important;

      hr {
        margin-bottom: 40px;
      }
    }
  }

  .news-article-wrapper {
    margin-left: 0;
    margin-right: 0;
    padding: 0 34px;
  }
}

@include media-max(rem(500px)) {
  .news-article-page-container {
    .newsroom-sidebar-small {
      padding: 0 10px !important;
    }
  }
  .news-article-wrapper {
    padding: 0 10px;
  }
}
