/* -The main header area with logo and navigation
-----------------------------------------------------------------------------*/
.start-page {
  .site-header {
    color: #fff;
  }
}
.site-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $color-header-background;
  color: gray;
  font-size: rem(28px);
  font-weight: $font-weight-medium;
  font-family: $font-stack-alt;
  z-index: 1000;
}

.site-header-logo,
.header-lang-item {
  padding: 24px 30px;
  flex-shrink: 0;
}

.header-lang-item {
  text-align: right;
  display: flex;
  align-items: center;
  font-family: $font-stack-main;
  color: #fff;

  .icon--globe {
    margin-right: 5px;
  }
  a {
    color: inherit;
    text-decoration: none;
    font-size: rem(16px);

    &:hover,
    &:focus {
      .text {
        text-decoration: underline;
      }
    }
  }
}

// Nav menu
.site-header-nav {
  flex-grow: 1;
  padding: 24px 0px;

  ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
  }
  li {
    &:not(:first-child) {
      margin-left: 50px;
    }
    a {
      text-decoration: none;
      color: inherit;

      &:hover,
      &:focus {
        color: #fff;
        text-decoration: underline;
      }
    }
    &.current-item {
      color: #fff;
    }
  }
}

/* ------------------- Small screen menu toggle ------------------- */
$header-small-height: 70px;

.menu-toggle {
  display: none !important;
  position: relative;
  padding-right: 35px;
  background: transparent !important;
  border: 1px solid transparent;
  color: inherit;
  font-size: rem(16px);

  &:hover,
  &:focus {
    border-color: currentColor;
  }
}
.menu-toggle-icon {
  top: 50%;
  right: 15px;

  &,
  &::before,
  &::after {
    position: absolute;
    width: 13px;
    height: 2px;
    background-color: currentColor;
    transition: background-color 0.15s ease;
  }
  &::before,
  &::after {
    content: '';
    display: block;
    left: 0;
    transition: transform 0.15s ease;
  }
  &::before {
    top: 0;
    transform: translateY(-5px);
  }
  &::after {
    bottom: 0;
    transform: translateY(5px);
  }
  .nav-open & {
    background-color: rgba(255, 255, 255, 0);

    &::before {
      transform: translateY(0) rotate(-225deg);
    }
    &::after {
      transform: translateY(0) rotate(225deg);
    }
  }
}

@include media-max($breakpoint-menu-toggle) {
  .site-header {
    height: $header-small-height;
  }

  .menu-toggle {
    display: inline-block !important;
    display: inline-flex !important;
    color: #fff;

    .nav-open & {
      color: gray;
      border: 1px solid;
      z-index: 10;
      transition: color 0.15s ease; // Button text fades in
    }
  }

  .site-header-logo,
  .header-lang-item {
    padding: 12px 15px;
    flex-shrink: 0;
  }
  .header-lang-item {
    padding-left: 0;
    .nav-open & {
      color: gray;
      z-index: 10;
    }
  }

  .site-header-nav {
    padding: 5px;
    text-align: right;
  }

  $toggled-menu-transition: 0.2s;

  // The pseudo elements are the black transparent and white opaque backgrounds
  .site-header-nav::before,
  .site-header-nav::after {
    content: '';
    display: block;
    position: absolute;
    height: $header-small-height;
    top: 0;
    right: 0;
  }

  .main-nav,
  .site-header-nav::before {
    visibility: hidden;
    width: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color $toggled-menu-transition ease,
      visibility 0s ease $toggled-menu-transition,
      width 0s ease $toggled-menu-transition;

    .nav-open & {
      visibility: visible;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      transition-delay: 0s;
    }
  }

  .main-nav > ul,
  .site-header-nav::after {
    width: 0;
    max-width: 350px;
    background: #fff;
    transform: translateX(100%);
    transition: transform $toggled-menu-transition ease;

    .nav-open & {
      transform: translateX(0);
      width: 100%;
    }
  }

  .main-nav {
    position: absolute;
    top: 100%;
    left: 0;
    height: calc(100vh - #{$header-small-height});
    overflow-x: hidden;
    overflow-y: auto;
    text-align: right;

    ul {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      display: inline-block;
      min-height: 100%;
    }
    li {
      position: relative;
      display: block;
      text-align: left;
      text-transform: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin: 0 !important;
      padding: 0;

      &.active > a::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 3px;
        width: 7px;
        height: 2px;
        margin-top: -1px;
      }
      a {
        padding: 7px 20px;
        color: $color-body-foreground !important;
      }
    }
  }

  .nav-open,
  .nav-open body {
    overflow: hidden;
  }
  .nav-open {
    .intro-content {
      z-index: 1;
    }
  }
}
