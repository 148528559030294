// -------------------- Color definitions -------------------- //

$color-gray-dark: #111;
$color-blue: #4a90e2;
$color-beige: #f1ece6;
$color-dark: #1a1a1a;
$color-gray: #292929;
$color-light-beige: #f7f2ec;
$color-red: #b32c25;

// Lightest text color on white that meets WCAG AA accessibility requirement
$color-lightest-accessible-gray-on-white: #767676;

// -------------------- Semantic assignments -------------------- //
// When possible and logical, use colors through more meaningful
// "use case" names.

// Main body colors
$color-body-foreground: $color-gray-dark;
$color-body-background: #fff;

// Disabled text
$color-disabled: $color-lightest-accessible-gray-on-white;

// Brand things like selection color
$color-main: $color-blue;

// Section block backgrounds
$color-section-beige-background: $color-beige;
$color-section-dark-background: $color-dark;

//Blocks
$color-callout-background: $color-light-beige;

$color-table-border: #999999;

// Header
$color-header-background: $color-dark;

// Footer
$color-footer-bottom: $color-gray;

// Forms
$color-form-field-border: #ccc;

// Status
$color-error: #ed404b;
$color-error-foreground: #d71421;
$color-error-background: #fae8e6;
$color-success: #49ad5a;
$color-success-foreground: #337a3b;
$color-success-background: #e7fae6;
