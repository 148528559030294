/* -Callout block
---------------------------------------------------------------------------- */
.callout-block-wrap {
  @include section-margin;
  @include wrap-custom(rem(800px));

  .section-block & {
    @include wrap-reset;
  }
}

.callout-block {
  position: relative;
}

.callout-icon {
  margin-left: 20px;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;

  .icon--chevron {
    height: 40px;
    width: 40px;
  }
}

.callout-block-inner {
  padding: 30px 12px 30px 25px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 5px 7px 0px rgba(193, 172, 147, 0.4);
  background: $color-callout-background;
  border: 1px solid #eee;
  transition: transform 0.15s ease, box-shadow 0.15s ease;

  .overlay-link:hover ~ &,
  .overlay-link:focus ~ & {
    text-decoration: underline;
    transform: scale(1.02);
    box-shadow: 0 3px 6px #0000004d;
  }
}
.callout-block-inner-content {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.callout-block-content {
  h2,
  h3,
  h4 {
    font-size: $h3 !important;

    @include media-max($breakpoint-type-small) {
      font-size: rem($h3 - 2px) !important;
    }
  }
}

// Small screens only
@include media-max(rem(500px)) {
  .callout-block-inner {
    padding: 20px 0 20px 15px;
  }
  .callout-block-image {
    img {
      max-width: 80px;
      margin-right: 1em;
    }
  }
  .callout-block-content {
    font-size: rem(14px);
  }

  .callout-icon {
    margin-left: 5px;

    .icon--chevron {
      height: 30px;
      width: 30px;
    }
  }
}

@include media-max(rem(400px)) {
  .callout-block-image {
    img {
      max-width: 50px;
    }
  }
}

// Small to medium screens only
@include media-between(rem(501px), rem(699px)) {
  .callout-block-image {
    max-width: 35%;
    margin-right: 25px;
  }
}

// Larger screens only
@include media-min(rem(700px)) {
  .callout-block-inner {
    display: flex;
    padding: 40px 12px 40px 50px;
    align-items: center;
  }
  .callout-block-image {
    flex: 1 1 auto;
    max-width: 170px;
    min-width: 120px;
    margin-right: 50px;
  }
}
