/* -Card slider/scroller
---------------------------------------------------------------------------- */

/* ------------------- Flickity base styles ------------------- */
// Adjusted version of the default Flickity CSS.
// Flickity v2.2.0 https://flickity.metafizzy.co

.flickity-enabled {
  position: relative;

  .hide-focus &:focus {
    outline: 0;
  }
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

// Draggable
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }
  .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
}

// flickity-button
.flickity-button {
  position: absolute;
  padding: 0;
  border: 0;
  border-radius: 0;
  z-index: 11;

  &:not(:disabled) {
    &:hover,
    &:focus {
      background: none;
      .flickity-button-icon {
        fill: rgba(0, 0, 0, 0.6);
      }
    }
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 5px $color-blue;
    }
  }
  &:disabled {
    opacity: 0;
    cursor: auto;
    // prevent disabled button from capturing pointer up event. Issue 716.
    pointer-events: none;
  }
}
.flickity-button-icon {
  fill: #000;
}

// Previous/next buttons
.flickity-prev-next-button {
  top: 50%;
  width: 65px;
  height: 100%;
  /* vertically center */
  transform: translateY(-50%);

  @include media-max(rem(450px)) {
    width: 30px;
  }

  &.previous {
    left: 0;
    background: linear-gradient(
      -90deg,
      rgba(102, 20, 81, 0) 0px,
      rgba(255, 255, 255, 1) 20px,
      rgba(255, 255, 255, 1) 65px
    );

    @include media-max(rem(450px)) {
      background: linear-gradient(
        -90deg,
        rgba(102, 20, 81, 0) 0px,
        rgba(255, 255, 255, 1) 5px,
        rgba(255, 255, 255, 1) 30px
      );
    }

    &:not(:disabled) {
      &:hover,
      &:focus {
        background: linear-gradient(
          -90deg,
          rgba(102, 20, 81, 0) 0px,
          rgba(255, 255, 255, 1) 20px,
          rgba(255, 255, 255, 1) 65px
        );

        @include media-max(rem(450px)) {
          background: linear-gradient(
            -90deg,
            rgba(102, 20, 81, 0) 0px,
            rgba(255, 255, 255, 1) 5px,
            rgba(255, 255, 255, 1) 30px
          );
        }
      }
      &:focus {
        outline: 0;
        box-shadow: 0 0 0 5px $color-blue;
        background: linear-gradient(
          -90deg,
          rgba(102, 20, 81, 0) 0px,
          rgba(255, 255, 255, 1) 20px,
          rgba(255, 255, 255, 1) 65px
        );

        @include media-max(rem(450px)) {
          background: linear-gradient(
            -90deg,
            rgba(102, 20, 81, 0) 0px,
            rgba(255, 255, 255, 1) 5px,
            rgba(255, 255, 255, 1) 30px
          );
        }
      }
    }
  }
  &.next {
    right: 0;
    background: linear-gradient(
      -90deg,
      rgba(255, 255, 255, 1) 0px,
      rgba(255, 255, 255, 1) 45px,
      rgba(255, 255, 255, 0) 65px
    );

    @include media-max(rem(450px)) {
      background: linear-gradient(
        -90deg,
        rgba(255, 255, 255, 1) 0px,
        rgba(255, 255, 255, 1) 25px,
        rgba(255, 255, 255, 0) 30px
      );
    }

    &:not(:disabled) {
      &:hover,
      &:focus {
        background: linear-gradient(
          -90deg,
          rgba(255, 255, 255, 1) 0px,
          rgba(255, 255, 255, 1) 45px,
          rgba(255, 255, 255, 0) 65px
        );

        @include media-max(rem(450px)) {
          background: linear-gradient(
            -90deg,
            rgba(255, 255, 255, 1) 0px,
            rgba(255, 255, 255, 1) 25px,
            rgba(255, 255, 255, 0) 30px
          );
        }
      }
      &:focus {
        outline: 0;
        box-shadow: 0 0 0 5px $color-blue;
        background: linear-gradient(
          -90deg,
          rgba(255, 255, 255, 1) 0px,
          rgba(255, 255, 255, 1) 45px,
          rgba(255, 255, 255, 0) 65px
        );

        @include media-max(rem(450px)) {
          background: linear-gradient(
            -90deg,
            rgba(255, 255, 255, 1) 0px,
            rgba(255, 255, 255, 1) 25px,
            rgba(255, 255, 255, 0) 30px
          );
        }
      }
    }
  }
  // Right to left
  /* .flickity-rtl &.previous {
    left: auto;
    right: 10px;
  }
  .flickity-rtl &.next {
    right: auto;
    left: 10px;
  } */
  .flickity-button-icon {
    position: absolute;
    top: 50%;
    height: 20px;
    width: 15px;
    transform: translateY(-50%);
  }
}

// Page dots
/* .flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;

  // .flickity-rtl & { direction: rtl; }
  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background: #333;
    border-radius: 50%;
    opacity: 0.25;
    cursor: pointer;

    &.is-selected {
      opacity: 1;
    }
  }
} */

/* flickity-fade */
.flickity-enabled.is-fade .flickity-slider {
  > * {
    pointer-events: none;
    z-index: 0;
  }
  > .is-selected {
    pointer-events: auto;
    z-index: 1;
  }
}

/* ------------------- Specifics ------------------- */

// Keep in sync with template
$card-slider-image-height: 160px;

.card-slider-container {
  margin-bottom: 30px;
}

.card-slider {
  position: relative;
  white-space: nowrap;
  .no-js & {
    overflow-x: auto;
  }

  .flickity-prev-next-button .flickity-button-icon {
    top: $card-slider-image-height / 2;
  }
}

.card-slider-item {
  min-width: 100px;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
  text-decoration: none;

  &:hover, &:focus {
    img {
      opacity: 0.8;
    }
  }

  &:not(:first-child) {
    margin-left: 30px;

    @include media-max(rem(450px)) {
      margin-left: 15px;
    }
  }
  img {
    max-width: none;
    border: 1px solid $color-beige;
  }
}
.card-slider-item-text {
  width: 1px;
  min-width: 100%;
  font-size: rem(12px);
  white-space: normal;


  p {
    margin-bottom: 0;
  }
}
.card-slider-item-title {
  font-weight: $font-weight-bold;
}
