/* -Basic content block
-----------------------------------------------------------------------------*/
// The content block is just a content stream. A content stream can also be
// part of other blocks, so the block template can't have a wrapper using one
// of the wrapping classes.
// All blocks are wrapped in a block-[name] container, so use that for the
// block itself rather than its content.
.block-content {
  @include wrap-thin;
  @include large-content-text;

  .section-block & {
    @include wrap-reset;
  }
}
