/* -Section block
---------------------------------------------------------------------------- */
.section-block {
  overflow: hidden;
}

.section-block-inner {
  .wrap,
  .wrap-thin,
  .wrap-wide,
  .wrap-full {
    @include wrap-reset;
  }
}

.section-block-inner--thin {
  @include wrap-thin;
}
.section-block-inner--wide {
  @include wrap;
}
.section-block-inner--mega-wide {
  @include wrap-wide;
}

.section-block--beige {
  background: $color-section-beige-background;
}
.section-block--dark {
  background: $color-section-dark-background;
  color: #fff;
}

@each $color in ('white' 'beige' 'dark') {
  .section-block--#{$color} + .section-block--#{$color} {
    @include section-pull-top;
  }
}
