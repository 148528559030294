// Font stacks
$font-stack-main: Arial, sans-serif;
$font-stack-alt: 'Creighton Pro', Arial, serif;
$font-stack-alt-second: 'Creighton Pro Book', Arial, serif;
$font-stack-mono: Consolas, 'Andale Mono', 'Lucida Console', monospace;

// Text style
// The base font size should preferably be kept at 16px as a definition of
// the browser default and used as a base for calculation. To globally
// increase the font size on the site, set it with a rule on a containing
// element instead of changin this.
$base-font-size: 16px;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Paragraph margins and such, one for pixels and one for em. The former should
// likely only be relevant for calculations.
$base-text-spacing: $base-font-size * 1.5;
$text-spacing: em($base-text-spacing);

// $text-spacing without 'em'
$base-line-height: $text-spacing / 1em;

// Heading sizes
$h1: 48px;
$h2: 40px;
$h3: 32px;
$h4: 28px;
$h5: 24px;
$h6: $base-font-size;

// Larger and smaller parapgraph text
$lead-text-size: 30px;
$quote-text-size: 30px;
$sub-text-size: 14px;
