/* -Into image with content
---------------------------------------------------------------------------- */
$intro-content-padding: 50px;

// Keep duration in sync with Intro.js
$intro-animation-duration: 4000ms;
$intro-animation-pan: 2%;
$intro-animation-scale: 1.1;

$initial-intro-transform: translate(0, 0) scale(1);
@keyframes intro-top-left {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate(-$intro-animation-pan, -$intro-animation-pan)
      scale($intro-animation-scale);
  }
}
@keyframes intro-top-right {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate($intro-animation-pan, -$intro-animation-pan)
      scale($intro-animation-scale);
  }
}
@keyframes intro-bottom-right {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate($intro-animation-pan, $intro-animation-pan)
      scale($intro-animation-scale);
  }
}
@keyframes intro-bottom-left {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate(-$intro-animation-pan, $intro-animation-pan)
      scale($intro-animation-scale);
  }
}

// Must have a wrapping column flex container for IE11
// https://github.com/philipwalton/flexbugs#flexbug-3
.intro-container {
  display: flex;
  flex-direction: column;
}

.intro {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 700px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  overflow: hidden;

  @media screen and (orientation: landscape) {
    min-height: 70vh;
  }
  @media screen and (orientation: portrait) {
    min-height: 50vh;
  }
}

.intro-bg {
  &,
  picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.intro-image-active {
  // Add buffer to keep the animation going as the active slide changes
  animation-duration: $intro-animation-duration + 2000ms;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

  &[data-direction='top-left'] {
    animation-name: intro-top-left;
  }
  &[data-direction='top-right'] {
    animation-name: intro-top-right;
  }
  &[data-direction='bottom-right'] {
    animation-name: intro-bottom-right;
  }
  &[data-direction='bottom-left'] {
    animation-name: intro-bottom-left;
  }
}

.intro-content {
  width: 100%;
  padding-top: rem($intro-content-padding);
  padding-bottom: rem($intro-content-padding);
  color: #fff;
  text-align: center;
  z-index: 2;

  h1,
  h2 {
    text-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    font-size: rem(52px);
    @include media-max(rem(950px)) {
      font-size: rem(38px);
    }
  }
  p {
    text-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
  }
}
.intro-content-inner {
  margin-top: 30px;
}

.intro-content-image-container {
  position: relative;
  width: 100%;
  text-align: center;
  z-index: 2;
}

.intro-content-image-inner {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 100%;
  }
}

@include media-min(rem(700px)) {
  .intro {
    justify-content: flex-end;
  }
  .intro-content-image-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
  .intro-content-inner {
    margin-top: 0;
  }
}

// Sub menu with anchors
.in-page-nav {
  position: sticky;
  top: 0;
  flex-wrap: wrap;
  padding: 10px 20px;
  color: #fff;
  font-size: rem(18px);
  font-family: $font-stack-alt;
  font-weight: $font-weight-bold;
  z-index: $z-in-page-nav;

  &:empty {
    display: none;
  }

  a {
    margin: 5px 15px;
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
    @include media-min(rem(600px)) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}
